.home-text {
    padding-top: 5%;
}

.btn {
    padding-left: 1%;
    padding-top: 2%;
}

a {
    text-decoration: none;
}

.homepageimage {
  height: 550px;
  width: 600px;
}

@media only screen and (max-width: 992px) {
    .homepageimage {
        display: none;
    } 
}