* {
  scroll-behavior: smooth !important;
}

.App {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient( #34DDB5 60%,#E9EE08 ); 
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient( #E9EE08 ,#34DDB5 60%); 
}

.logo {
  height: 50px;
  width: 50px;
}

.logo2 {
  height: 50px;
  width: 50px;
  padding-left: 50%;
}

.card {
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  height: 400px;
  margin: auto;
  font-family: arial;
  padding: 5%;
}

.contactAniketImage {
  width: 55%;
  height: 40%;
  border: 2px solid #1ee2b3;
  border-radius: 50%;
}

.contactTitle {
  color: grey;
  font-size: 18px;
}

.contactButton {
  border-radius: 50%;
}

.home-text {
  padding-left: 1.5%;
}

.serviceSection {
  padding: 2%;
}

.blogSection {
  padding: 2%;
}

@media only screen and (min-width: 600px) {
    .smallScreen404 {
      display: none;
    }
}

@media only screen and (max-width: 600px) {
    .largeScreen404 {
      display: none;
    }
}

@media only screen and (min-width: 1000px) {
    .logo {
        display: none;
    }
    .homepageimagetop {
      display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .logo2 {
        display: none;
    }
    .ContactImg {
      display: none;
    }
}

.footer {
  padding-top: 1%;
  padding-bottom: 1%;
}

.socialMedia {
  padding: 1%;
}

.name {
  color: #00CC9B;
}

.about {
  padding-top: 3%;
  padding-bottom: 1%;
}

.about-text {
  padding-top: 2.5% !important;
}

.aboutPage {
  padding: 0 2%;
}

.heading {
  padding-bottom: 3%; 
}

.portfolioname {
  padding-bottom: 1%;
}

.portfoliolink {
  padding-left: 10%;
  padding-right: 10%;
}

.ContactImg {
  height: 400px;
  width: 400px;
  padding: 15%;
}


.communityHeading {
  padding-top: 1%;
  padding-bottom: 3%;
}


.flip-box {
  background-color: transparent;
  width: 200px;
  height: 200px;
  border: 1px solid #ffffff;
  border-radius: 5px;
	box-shadow: 1px 1px 4px 4px rgba(1, 1, 1, 0.06); 
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side */
.flip-box-front {
  background-color: #fff;
  color: black;
}

/* Style the back side */
.flip-box-back {
  background-color: #1EE2B3;
  color: #000;
  transform: rotateY(180deg);
}

.communityImage {
  height: 130px;
  width: 150px;
  align-content: center;
  padding-top: 18%;
}

.mscp {
  height: 130px;
  width: 140px;
  align-content: center;
  padding-top: 18%;
}

.gsp {
  height: 120px;
  width: 170px;
  align-content: center;
  padding-top: 18%;
}

.csi {
  height: 150px;
  width: 150px;
  align-content: center;
  padding-top: 13%;
}

.postman {
  height: 150px;
  width: 150px;
  align-content: center;
  padding-top: 13%;
}

.pageNotFoundContent {
  padding-top: 3% !important;
}

.inviteMeText {
  font-size: 2em !important;
  margin-bottom: -0.1% !important;
}

.myBio {
  padding-left: 4%;
  padding-right: 4%;
}

.MyBioImage {
  width: 280px;
  height: 300px;
}

.myBioBio {
  padding-left: 4%;
  padding-right: 4%;
}