/*Used flex-box for better box placement*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.header__main { /*whole navbar*/
  display: flex;
  background-color: #00cc9b;
  height: fit-content;
  width: 100%;
  padding: 5px 0px 5px 0px;
  align-items: center;
  box-shadow: 5px 2px 5px 3px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', 'sans-serif';
  position:fixed;
  z-index:99;
  font-size: 1.1em;
}
.header__menu{ /*main block of all the links*/
	display:flex;
	justify-content: flex-end;
	width:100%;
	padding-right: 20px;
}
.nav__links{ /*individual links*/
	margin-left:20px;
	padding:7px 12px 7px 12px;
	border-radius: 20px;
	cursor: pointer;
	transition: 0.3s;
	text-decoration: none;
	color: black;
}
.nav__links:hover{
	background-color: #1affc8;
	text-decoration: none;
}
.header__logo{ /*dsc logo on the navbar*/
	padding-left:20px;
}
.header__logo > img{
	object-fit: contain;
	height: 50px;
}
.header__burger{ /*the burger navbar when layout is changed*/
	display:none;
}


@media screen and (max-width: 750px){ /*when width is less than 750px below defined properties will be enabled*/
	.header__menu{
		display:none
	}
	.header__burger{
		display:flex;
		justify-content: flex-end;
		width:100%;
		padding-left:20px;
		padding-right: 10px;
	}
	.header__burger>i{
		padding:7px 12px 8px 12px;
	}
	.header__burger>i:hover{
		border-radius:5px;
		border:1px solid gray;
		padding:7px 12px 7px 12px;
	}
	.resp{
		display:flex;
		width:100%;
		align-items:center;
	}
}

@media screen and (max-width: 750px){
	.header__main.responsive{
		display: flex;
		flex-direction:column;
		padding-top: 10px;
	}
	.header__menu.responsive{
		margin-top:20px;
		display: flex;
		flex-direction:column;
		text-align:center;
	}
}